import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Textarea,
  Spinner,
  Portal,
  Link,
  Select,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { Button, IconButton } from "@chakra-ui/button";
import { GoComment } from "react-icons/go";
import { Icon, ViewOffIcon, CloseIcon } from "@chakra-ui/icons";
import { format, formatDistance } from "date-fns";
import numbro from "numbro";
import styled from "@emotion/styled";
import twitter from "twitter-text";
import { Image } from "@chakra-ui/image";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { hideTweet, refreshProfilePic } from "../../../controllers/bookmark";
import FocusLock from "react-focus-lock";
import { FiMoreHorizontal, FiMoreVertical, FiUser } from "react-icons/fi";
import { BsCheckCircle } from "react-icons/bs";
import { generate } from "../../../controllers/variations";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { isAllowed } from "controllers/subscription";
import { SiGoogletranslate } from "react-icons/si";
import { CgDanger } from "react-icons/cg";
import { likePost } from "controllers/miscellaneous";
import { BiCopy } from "react-icons/bi";
import { AiFillLike, AiOutlineMinusCircle } from "react-icons/ai";
import UserPopup from "../user-popup";
import { useClipboard, useDisclosure } from "@chakra-ui/hooks";
import { useSession } from "next-auth/react";
import { postData, useOnClickOutside } from "../../../utils/helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import * as analytics from "../../../utils/analytics";
import { CollectionDropdown } from "../../collection-dropdown";
import { firebaseClient, getToken } from "../../../firebaseClient";
import { TweetContext } from "../../../context/tweetContext";
import { MainContext } from "../../../context/mainContext";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { getAccount } from "../../../utils/sessionHelper";
import { useFirebaseUser } from "../../../utils/useFirebaseUser";
import { RichContentBox } from "./richContentBox";
import { PostInPost } from "./postInPostPreview";
import { ImageNextChakra } from 'components/common/image-next-chakra';
import { CarouselPreview } from './carouselPreview';
import { textStyle, variant } from "theme/names";

// const StyledText = styled(Text)`
//   a {
//     color: ${() => theme.colors.twitter[400]};
//   }
// `; 

let maxHeight = 238;
let border = 0;

const Tweet = ({
  tweet,
  savedTweet = [],
  disableBookmarkButton,
  disableHideButton,
  disableMainHideButton = true,
  refresher = undefined,
  removeTweet = undefined as any,
  mode = "copy",
  requestVariation = undefined,
  setSelectedTweet = undefined,
  disableRequest,
  isVariation = false,
  disableHeader = false,
  disableFooter = false,
  disableEditTweet = false,
  disableSubMenu = false,
  disableName = true,
  disableIntro = true,
  disableFollowersCount = true,
  forceDisplayName = false,
  disableCopy = false,
  disableLike = false,
  isSelectable = false,
  disableCheckmark = true,
  disableVotes = true,
  disableUserPopup = false,
  toggleSelection = undefined,
  disableAddToQueue = true,
  disableTranslate = false,
  forceSelectedTweet = undefined as any,
  voteAction = undefined as any,
  overrideRootUrl = undefined as any,
  disablePostOnTwitter = true,
  disableRefreshProfilePic = false,
  showNumComments = false,
  setExcludedUsers = undefined as undefined | Dispatch<SetStateAction<string[]>>,
}) => {
  const { hasCopied, onCopy } = useClipboard(
    // tweet.data.full_text + `\n\nInspired by @${tweet.data.twUserName}`
    tweet.data.full_text
  );
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"

  const isAuthenticated = useFirebaseUser();
  const [isSaved, setIsSaved] = React.useState(false);
  // const [isSelectedInternal, setIsSelectedInternal] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  //@ts-ignore
  const inLists = [...new Set(...savedTweet.filter(x => x.id_str === tweet.data.id_str).map(s => s.lists), tweet.data.lists)];
  const initRef = React.useRef(null);

  const [isLoadingVariation, setIsLoadingVariation] = React.useState(false);

  const [editValue, setEditValue] = React.useState("");
  const [isOpenPopOver, setIsOpenPopOver] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [nbChar, setNbChar] = React.useState<number>(0);
  const [typingTimeout, setTypingTimeout] = React.useState<any>(undefined);

  const openPopOver = () => setIsOpenPopOver(true);
  const closePopOver = () => setIsOpenPopOver(false);
  const tweetContext = React.useContext(TweetContext);
  const mainContext = React.useContext(MainContext);

  const [languageTrans, setLanguageTrans] = React.useState<any>("fr");
  const [isLoadingTrans, setIsLoadingTrans] = React.useState<any>(false);

  const [isOpenBookmark, setIsOpenBookmark] = React.useState(false);
  const refBookmark = React.useRef<any>();
  useOnClickOutside(refBookmark, async () => { await new Promise(resolve => setTimeout(resolve, 200)); setIsOpenBookmark(false); });
  const { updateOnboardSteps } = useOnboardingSteps();

  const [isOpenUserProfile, setIsOpenUserProfile] = React.useState(false);
  const refUserProfile = React.useRef<any>();
  useOnClickOutside(refUserProfile, async () => { await new Promise(resolve => setTimeout(resolve, 200)); setIsOpenUserProfile(false); });

  const userProfileBg = useColorModeValue("white", "gray.800");
  const colorGray = useColorModeValue("gray.500", "gray.300");
  const colorLightGray = useColorModeValue("gray.50", "gray.700");
  const colorBlack = useColorModeValue("#1E1E1E", "white");
  const colorWhite = useColorModeValue("white", "gray.800");

  const [hasInit, setHasInit] = React.useState<any>(false);

  const [vote, setVote] = React.useState(0);

  const [isCollapsed, setIsCollapsed] = React.useState<any>(true);
  const [isAtMaxHeight, setIsAtMaxHeight] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<any>(null);

  useEffect(() => {
    let newHeight = ref?.current ? ref.current.clientHeight : 0;
    // console.log("ref?.current: " + ref?.current);
    // console.log("height: " + newHeight);
    setHeight(newHeight);
    setIsAtMaxHeight((maxHeight - (2 * border)) === newHeight);
  }, []);

  useEffect(() => {
    //@ts-ignore
    const isSaved = savedTweet.map(tw => tw.id_str).includes(tweet.data.id_str) || tweet?.data?.lists?.length;
    setIsSaved(isSaved);
  }, [savedTweet]);

  useEffect(() => {
    if (!hasInit && isAuthenticated) {
      setHasInit(true);
      if (tweet?.data?.user?.dashEntityUrn) {
        let data = { ...tweet.data };
        data.user.id_str = tweet?.data?.user?.dashEntityUrn;
      }
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (!isVariation && !tweet?.data?.user?.profile_image_url_https && tweet?.data?.user?.dashEntityUrn)
      handleImageError();
  }, []);

  const handleImageError = async (e = undefined as any) => {
    console.log("onError on image loading: " + tweet.data.user.profile_image_url_https, e);

    let isUsername = false;

    let data = { ...tweet.data };
    data.user.id_str = tweet?.data?.user?.dashEntityUrn;

    if (!data.user.id_str) {
      data.user.id_str = tweet?.data?.user?.username;
      isUsername = true;
    }

    if (data.user.id_str)
      refreshProfilePic(data, "linkedin", tweet.data.type == "linkedin" ? tweet.data.user.image : tweet.data.user.profile_image_url_https, isUsername);
    else
      console.log("no id_str for user to refresh image");
  }

  const handleOnClickExcludeUser = () => {
    if (!setExcludedUsers) {
      return
    }

    toast.success(`${tweet.data.user.username} excluded from the feed`);

    setExcludedUsers(prevExcludedUsers => {
      if (prevExcludedUsers.includes(tweet.data.user.username)) {
        return prevExcludedUsers;
      }
      return [...prevExcludedUsers, tweet.data.user.username];
    }
    )
  }

  // console.log({inLists});
  // entities = JSON.parse(tweet.data.entities),
  // entities = JSON.parse(tweet.data.entities),
  // tweet.data.extended_entities
  // console.log(tweet.data);
  // tweet.data.user.entities = JSON.parse(tweet.data.user.entities);
  if (!tweet.data.fullText || !!tweet.data.fullText) {
    tweet.data.fullText = tweet.data.text;
    tweet.data.full_text = tweet.data.text;
  }

  if (!tweet.data.fullTextAppended) {
    // tweet.data.fullTextAppended = isVariation ? tweet.data.full_text : tweet.data.full_text + "\n\nInspired by @" + tweet.data.user?.screen_name;
    tweet.data.fullTextAppended = tweet.data.full_text;
  }

  // console.log("render tweet");
  // let isSelected = isSelectedInternal && (!forceSelectedTweet || forceSelectedTweet.id_str === tweet.data.id_str) ;
  // console.log(forceSelectedTweet);
  // console.log("created_at: ", tweet.data.created_at);
  // console.log(tweet.data.text);
  // console.log(tweet.data.fullText);
  //for posts in post reshareUpdate is supposed to be checked.

  let richMediaBox: any = false;
  if (tweet?.data?.zFull?.content) {
    tweet.data.zFull.content.hrefLink = tweet?.data?.url ?? ("https://www.linkedin.com/feed/update/" + tweet?.data?.id);
    tweet.data.zFull.content.carousel_document_link = tweet.data?.zFull?.carousel_document_link || "";
    richMediaBox = <RichContentBox medias={tweet?.data?.zFull?.content} />;
  }

  let postInPost: any = false;
  if (tweet?.data?.zFull?.resharedUpdate) { //check if postInPost
    tweet.data.zFull.resharedUpdate.parentCreatedAt = tweet?.data?.created_at;
    postInPost = <PostInPost postInPostDetails={tweet?.data?.zFull?.resharedUpdate} />;
  }

  const userPhotoAndName = () => {

    let name = tweet.data.user.name ?? tweet.data.user.firstName + " " + tweet.data.user.lastName;
    let username = tweet.data.user.screen_name ?? tweet.data.user.username;
    let userIntro = tweet?.data?.user?.title || tweet?.data?.user?.description || tweet?.data?.user?.about;
    // console.log(mode, ';check mode for other pages');

    return (
      <Box display="flex" alignItems={"center"}>
        <ImageNextChakra
          key={tweet.data.user.profile_image_url_https}
          w="32px"
          h="32px"
          borderRadius={20}
          overflow="hidden"
          ml={0.5}
          //@ts-ignore
          src={tweet.data.type == "linkedin" ? tweet.data.user.image : tweet.data.user.profile_image_url_https}
          alt={"Profile picture of " + name}
          onClick={() => {
            if (!disableUserPopup)
              setIsOpenUserProfile(!isOpenUserProfile);
          }}
          isSwitchToAvatar={true}
          unoptimized={"true"}
          avatarProps={{ h: 8, w: 8 }}
        />
        {
          // linkedinPost needs a little cleanup, especially with regards to this function useage -- manoj
          ((!disableName || !disableIntro) && mode != "answer") && (
            <Flex ml={3} flexDirection="column" alignItems={"flex-start"}>
              {
                (
                  !disableName &&
                  <>
                    <Text textStyle={textStyle["body.medium.light"]} fontSize={12}
                      fontWeight="600" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" width="90px">
                      {name}
                    </Text>
                    <Text textStyle={textStyle["body.medium.light"]} as="a" href={(tweet?.data?.type == "linkedin" ? "https://taplio.com/lookup/" : "https://www.linkedin.com/in/") + tweet?.data?.user?.username} target="_blank" fontSize={11} color={theme.colors.gray[400]} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" width="90px">
                      {"@" + username}
                    </Text></>
                )
              }

              {
                (
                  (!disableIntro && userIntro) &&
                  <Text maxW={"90px"} textStyle={textStyle["body.medium.light"]} as="a" href={(tweet?.data?.type == "linkedin" ? "https://taplio.com/lookup/" : "https://www.linkedin.com/in/") + username} target="_blank" fontSize={11} color={theme.colors.gray[400]} overflow="hidden" textOverflow="ellipsis" whiteSpace={"nowrap"} title={userIntro}>
                    {userIntro}
                  </Text>
                )
              }
            </Flex>
          )
        }
      </Box>
    )
  }

  const handleVariations = async () => {
    analytics.log(
      "hit_request_variation",
      analytics.formatTweet(tweet.data)
    );
    updateOnboardSteps("aiWriting", "schedule");
    // //@ts-ignore
    // tweetContext.requestVariation(tweet.data);
    if (session?.user?.data?.skipPopupVariationAuto &&
      (session?.user?.data?.creditsAuto ||
        isAllowed(session.user, "ai_variation"))) {
      setIsLoadingVariation(true);
      let result = await generate(
        session,
        tweet.data,
        1,
        getAccount(session)?.tempLevel,
        mainContext
      );
      setIsLoadingVariation(false);

      if (result?.success &&
        result?.data?.variations?.length) {
        let newVariation = result?.data?.variations[0];
        //@ts-ignore
        tweetContext.newTweet({ text: newVariation });
        // @ts-ignore
        tweetContext.setIsTweetTextChanged(true);
        //@ts-ignore
        tweetContext.open();
        toast.success("Variation pasted in composer");
      }
    } else {
      //@ts-ignore
      tweetContext.requestVariationAuto(tweet.data);
    }
  }

  const [hoverVariations, setHoverVariations] = useState(false)
  const [hoverCollections, setHoverCollections] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)


  return (
    <Box
      key={tweet.data.id}
      minW="250px"
      layerStyle="bgBorderRoundedHoverShadow"
      textStyle="body.medium.standard"
      onClick={() => {
        if (isSelectable || toggleSelection) {
          console.log("click tweet");
          // setIsSelectedInternal(!isSelectedInternal);
          if (isSelectable) setIsSelected(!isSelected);
          //@ts-ignore
          toggleSelection && toggleSelection(tweet.data);
        }
      }}
      _hover={
        isSelectable || toggleSelection ? {
          backgroundColor: "gray.100",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
          borderColor: "transparent",
        } : {
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
          borderColor: "transparent",
        }
      }
      cursor={isSelectable || toggleSelection ? "pointer" : "unset"}
    >
      <Box css={{ filter: tweet.data.isBlurred && "blur(3px)" }}>
        {!disableHeader && (
          <>
            <Flex p={2} alignItems="center" justifyContent="space-between">
              {isVariation ? (
                <Box display="flex">{userPhotoAndName()}</Box>
              ) : (
                <>
                  <Stack isInline alignItems="center">
                    {!disableUserPopup && tweet.data.user?.username ? (
                      <Box position="relative">
                        {userPhotoAndName()}
                        {isOpenUserProfile && (
                          <Box
                            position="absolute"
                            top="40px"
                            left="0"
                            w="280px"
                            bg={userProfileBg}
                            boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;"
                            rounded="md"
                            zIndex="1"
                            ref={refUserProfile}
                          >
                            <UserPopup
                              user={tweet.data.user}
                              tweet={tweet.data}
                              type={tweet.data.type}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      userPhotoAndName()
                    )}
                    {mode == "answer" ? (
                      <Flex alignItems="flex-start" flexDirection={"column"}>
                        {
                          ((tweet?.data?.user?.screen_name || tweet?.data?.user?.username) &&
                            <Text
                              textStyle={textStyle["body.medium.light"]}
                              w="150px"
                              noOfLines={1}
                              wordBreak={"break-all"}
                            >
                              {tweet.data.user.name}
                            </Text>
                          )
                        }
                        {
                          (
                            !disableIntro && (tweet?.data?.user?.title || tweet?.data?.user?.description) &&
                            <Text
                              textStyle={textStyle["body.medium.light"]}
                              maxW={"150px"}
                              noOfLines={1}
                              wordBreak={"break-all"}
                              as="a"
                              href={(tweet?.data?.type == "linkedin" ? "https://taplio.com/lookup/" : "https://www.linkedin.com/in/") + (tweet?.data?.user?.screen_name || tweet?.data?.user?.username)} target="_blank"
                              fontSize={11}
                              color={theme.colors.gray[400]} overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace={"nowrap"}
                              title={tweet?.data?.user?.title || tweet?.data?.user?.description}
                            >
                              {tweet?.data?.user?.title || tweet?.data?.user?.description}
                            </Text>
                          )
                        }
                        {/* <Text textStyle={textStyle["body.medium.light"]} mt={1} ml={2} mr={2} fontSize="md">{numbro(tweet.data.user.followers_count).format({
                                        // mantissa: 1,
                                        average: true,
                                        // totalLength: 3,
                                      }).replace(".0", "")}</Text>
                                  <Text textStyle={textStyle["body.medium.light"]} mt={2} fontSize="11px" color="gray.400">Followers</Text> */}
                      </Flex>
                    ) : (
                      <></>
                    )}
                    {forceDisplayName && (
                      <Flex ml={3} flexDirection="column">
                        <Text
                          textStyle={textStyle["body.medium.light"]}
                          fontSize={12}
                          // color={theme.colors.gray[500]}
                          fontWeight="600"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          width="90px"
                        >
                          {tweet.data.user.firstName +
                            " " +
                            tweet.data.user.lastName}
                        </Text>
                        {tweet?.data?.user?.username && (
                          <Text
                            textStyle={textStyle["body.medium.light"]}
                            as="a"
                            href={(tweet.data.type == "linkedin" ? "https://www.linkedin.com/in/" : "https://twitter.com/") + tweet.data.user.username}
                            target="_blank"
                            fontSize={11}
                            color={theme.colors.gray[400]}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            width="90px"
                          >
                            {"@" + tweet.data.user.username}
                          </Text>
                        )}
                      </Flex>
                    )}
                  </Stack>
                  <Stack isInline alignItems="center" justifyContent="center">
                    <Link
                      variant={variant.Link.light}
                      noOfLines={1}
                      wordBreak={"break-all"}
                      maxWidth="110px"
                      fontSize={12}
                      mt={1}
                      mr={1}
                      href={
                        tweet.data.url ??
                        "https://www.linkedin.com/feed/update/" + tweet.data.id
                      }
                      target="_blank"
                    >
                      {
                        //@ts-ignore
                        // format(new Date(tweet.data.created_at), "Pp")
                        mode == "answer"
                          ? formatDistance(
                            new Date(tweet.data.created_at ?? Date.now()),
                            new Date(),
                            { addSuffix: true }
                          )
                          : format(
                            tweet.data.created_at
                              ? tweet.data.created_at.toDate
                                ? tweet.data.created_at.toDate()
                                : new Date(tweet.data.created_at)
                              : new Date(Date.now()),
                            "yyyy/MM/dd"
                          )
                      }
                    </Link>
                    {isAuthenticated && !disableRequest && (
                      <Box
                        {...{
                          onMouseEnter: () => {
                            setHoverVariations(true);
                          },
                          onMouseLeave: () => {
                            setHoverVariations(false);
                          },
                          onBlur: () => {
                            setHoverVariations(false);
                          },
                          borderRadius: "10px"
                        }}
                      >
                        <IconButton
                          aria-label="AI Variation"
                          variant={variant.Button.secondaryAI}
                          size="sm"
                          isLoading={isLoadingVariation}
                          onClick={handleVariations}
                          cursor="pointer"
                          icon={
                            <Flex {...{
                              opacity: hoverVariations ? 1 : 0.65,
                            }}>
                              {/* <Icon viewBox="0 0 13 16"> */}
                              <Image src={"/theme/ai-icon.svg?v=12"} w={13} h={16} />
                              {/* <defs>
                                  <linearGradient
                                    id="Gradient2"
                                    x1="0"
                                    x2="0"
                                    y1="0"
                                    y2="1"
                                  >
                                    <stop offset="20%" stopColor={"#e86d8a"} />
                                    <stop offset="80%" stopColor={"#527ff0"} />
                                  </linearGradient>
                                </defs>
                                <path
                                  d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                  fill="url(#Gradient)"
                                /> */}
                              {/* </Icon> */}
                            </Flex>
                          }
                          mr={0}
                        />
                      </Box>
                    )}
                    {/* <Tooltip label={isSaved ? "Add/remove this tweet from collections" : "Save tweet for later access."}> */}
                    <Box>
                      {isAuthenticated && !disableBookmarkButton && (
                        <CollectionDropdown
                          inLists={inLists}
                          refresher={refresher}
                          tweet={tweet.data}
                          isOpenPopOver={isOpenPopOver}
                          closePopOver={closePopOver}
                          setIsSaved={setIsSaved}
                          isOpenBookmark={isOpenBookmark}
                          refBookmark={refBookmark}
                          // collectionLoading={collectionLoading}
                          setCollectionLoading={setCollectionLoading}
                          trigger={
                            <Box
                              {...{
                                onMouseEnter: () => {
                                  setHoverCollections(true);
                                },
                                onMouseLeave: () => {
                                  setHoverCollections(false);
                                },
                                onBlur: () => {
                                  setHoverCollections(false);
                                },
                                borderRadius: "10px"
                              }}

                            >
                              <IconButton
                                aria-label="Bookmark"
                                variant={"secondaryBookmark"}
                                size="sm"
                                onClick={async () => {
                                  setIsOpenBookmark(!isOpenBookmark);
                                }}
                                cursor="pointer"
                                isLoading={collectionLoading}
                                icon={
                                  <Flex {...{
                                    opacity: isSaved ? 1 : hoverCollections ? 1 : 0.65,
                                  }}>
                                    <Icon
                                      viewBox="0 0 17 17"
                                    >
                                      {isSaved ? (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      )}
                                    </Icon>
                                  </Flex>
                                }
                                mr={0}
                              />
                            </Box>
                          }
                        />
                      )}
                    </Box>
                    {/* </Tooltip> */}
                  </Stack>
                </>
              )}
            </Flex>
            <Divider mb={0.5} />
          </>
        )}
        <Box
          overflow="hidden"
          maxH={isCollapsed ? maxHeight : "none"}
          position="relative"
          ref={ref}
          pb={isCollapsed ? 0 : 5}
        >
          {(isAtMaxHeight || !isCollapsed) && (
            // true && (
            <Button
              position="absolute"
              right={0}
              bottom={0}
              variant={variant.Button.tertiaryLight}
              // bg={colorLightGray}
              size="xs"
              pr={2}
              pl={2}
              // color={colorBlack}
              // fontWeight="400"
              // fontSize="sm"
              // bg="transparent"
              // backdropFilter="blur(4px)"
              _focus={{ boxShadow: "none" }}
              onClick={(e) => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              <i>{isCollapsed ? "more" : "less"}</i>
            </Button>
          )}
          {!disableCopy ? (
            <CopyToClipboard
              text={tweet.data.fullTextAppended}
              onCopy={() => {
                // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
                toast.success("Post copied to clipboard");
              }}
            >
              <Text
                // h="200px"
                textStyle={textStyle["body.medium.light"]}
                // bg="red.200"
                p={1}
                pl={3}
                pr={2}
                whiteSpace="pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: tweet.data.fullText,
                }}
              />
            </CopyToClipboard>
          ) : (
            <Text
              textStyle={textStyle["body.medium.light"]}
              // bg="red.200"
              // p={1}
              // pl={3}
              // pr={2}
              p={3}
              pl={5}
              pr={4}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{
                __html: tweet.data.fullText,
              }}
            />
          )}
        </Box>
        {richMediaBox && (
          <Box w="100%" display="flex">
            {richMediaBox}
          </Box>
        )}
        {tweet.data?.zFull?.carousel_document_link && (
          <Box w="100%" display="flex">
            {
              <CarouselPreview
                docURL={tweet.data?.zFull?.carousel_document_link}
                docName={tweet?.data?.full_text?.substring(0, 27) || ""}
              />
            }
          </Box>
        )}
        {postInPost && (
          <Box w="100%" display="flex">
            {postInPost}
          </Box>
        )}
        {tweet.data?.extended_entities?.media &&
          tweet.data?.extended_entities?.media.length > 0 && (
            <Box w="100%" display="flex">
              {tweet.data?.extended_entities?.media.map((media, index) => (
                <Box key={tweet.data.id_str + index}>
                  <Image objectFit="cover" p={2} src={media.media_url_https} />
                </Box>
              ))}
            </Box>
          )}
        {!disableFooter && (
          <>
            <Divider my={2} />
            <Flex alignItems="center" justifyContent="space-between" pt={0} pb={2} px={2}>
              {!isVariation ? (
                <Box display="flex" flexDir="row">
                  {!disableLike && tweet.data.numLikes !== undefined && (
                    <Flex
                      alignItems="center"
                      mr={3}
                      ml={3}
                      cursor="pointer"
                      onClick={async () => {
                        if (
                          getAccount(session)?.access_token &&
                          tweet?.data?.id_str
                        ) {
                          toast.promise(
                            new Promise(async (resolve, reject) => {
                              const dataToSend = {
                                postUrn: tweet.data?.idUgcPost || tweet.data.id_str,
                                accessToken: getAccount(session).access_token,
                                idAccount: getAccount(session).idLinkedinOfficial ?? getAccount(session).idAccount,
                              };
                              let data = await likePost(
                                session,
                                dataToSend,
                                tweet.data
                              );
                              if (data?.success) resolve(data);
                              else {
                                console.log(
                                  "Error in liking post: " + data.error
                                );
                                reject();
                              }
                            }),
                            {
                              loading: "liking ... ",
                              success: "Post liked",
                              error: "Couldn't like post",
                            }
                          );
                        } else if (tweet?.data?.id_str) {
                          toast.error(
                            "We need more authorization to like posts"
                          );
                          //@ts-ignore
                          tweetContext.onOpenCredentials();
                        } else {
                          toast.error("Not possible to like this post");
                        }
                      }}
                    >
                      {/* <Box
                        mr={2}
                        as={AiFillLike}
                        color={theme.colors.blue[500]}
                      /> */}
                      <Icon viewBox="0 0 16 15" mr={2}>
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 5.5H1C0.4375 5.5 0 5.9375 0 6.46875V13.4375C0 13.9688 0.4375 14.4062 1 14.4062H3C3.53125 14.4062 4 13.9688 4 13.4375V6.5C4 5.96875 3.53125 5.5 3 5.5ZM16 6.59375C16 5.46875 15.0312 4.53125 13.9062 4.53125H10.7812C11.0625 3.75 11.25 3.0625 11.25 2.65625C11.25 1.59375 10.4062 0.53125 9.03125 0.53125C7.59375 0.53125 7.1875 1.53125 6.875 2.34375C5.875 4.875 5 4.40625 5 5.25C5 5.65625 5.3125 6 5.75 6C5.90625 6 6.0625 5.96875 6.1875 5.84375C8.59375 3.9375 8 2.03125 9.03125 2.03125C9.5625 2.03125 9.75 2.40625 9.75 2.65625C9.75 2.90625 9.5 3.90625 8.9375 4.90625C8.875 5.03125 8.84375 5.15625 8.84375 5.28125C8.84375 5.71875 9.1875 6 9.59375 6H13.875C14.2188 6.03125 14.5 6.28125 14.5 6.59375C14.5 6.90625 14.25 7.1875 13.9375 7.1875C13.5312 7.21875 13.2188 7.5625 13.2188 7.9375C13.2188 8.4375 13.5938 8.4375 13.5938 8.84375C13.5938 9.625 12.5 9.21875 12.5 10.1562C12.5 10.5 12.6875 10.5625 12.6875 10.8438C12.6875 11.5625 11.75 11.2812 11.75 12.125C11.75 12.2812 11.8125 12.3125 11.8125 12.4375C11.8125 12.75 11.5312 13.0312 11.2188 13.0312H9.5625C8.75 13.0312 7.96875 12.75 7.34375 12.2812L6.1875 11.4062C6.0625 11.3125 5.90625 11.25 5.75 11.25C5.3125 11.25 4.96875 11.625 4.96875 12C4.96875 12.25 5.09375 12.4688 5.28125 12.625L6.4375 13.4688C7.34375 14.1562 8.4375 14.5 9.5625 14.5H11.2188C12.3125 14.5 13.2188 13.6562 13.2812 12.5625C13.8438 12.1875 14.1875 11.5625 14.1875 10.8438C14.1875 10.75 14.1875 10.6562 14.1875 10.5625C14.7188 10.1875 15.0938 9.5625 15.0938 8.84375C15.0938 8.6875 15.0625 8.53125 15.0312 8.34375C15.5938 7.96875 16 7.34375 16 6.59375Z"
                            fill="#0A66C2"
                          />
                        </svg>
                      </Icon>
                      {numbro(tweet.data.numLikes)
                        ?.format({
                          // mantissa: 1,
                          average: true,
                          totalLength: 1,
                        })
                        .replace(".0", "")}
                    </Flex>
                  )}
                  {(tweet.data.numComments ||
                    tweet.data.numComments === 0) && showNumComments && (
                      <Flex alignItems="center">
                        <Box
                          mr={2}
                          as={GoComment}
                          color={"#0A66C2"}
                          title={"Comments"}
                        />
                        {numbro(tweet.data.numComments)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                  {(tweet?.data?.user?.followersCount ||
                    tweet?.data?.user?.followersCount === 0) && !disableFollowersCount && (
                      <Flex alignItems="center">
                        <Box
                          mr={2}
                          ml={2}
                          as={FiUser}
                          color={"#0A66C2"}
                          title={"Follower count"}
                        />
                        {numbro(tweet.data?.user?.followersCount)
                          ?.format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Flex>
                    )}
                </Box>

              ) : (
                <Box></Box>
              )}
              <Stack isInline spacing={1} alignItems="center">
                {!disableMainHideButton && removeTweet && (
                  <Button
                    variant="secondary"
                    leftIcon={<Icon as={CloseIcon} fontSize="xs" />}
                    onClick={async () => {
                      analytics.log("hit_hide_tweet", analytics.formatTweet(tweet.data));
                      hideTweet(tweet.data, getAccount(session)?.id, -1);
                      removeTweet && removeTweet(tweet.data);
                    }}
                  >
                    {"Hide"}
                  </Button>
                )}
                {!disableAddToQueue && (
                  <Button
                    bg="gray.600"
                    colorScheme="blackAlpha"
                    color="white"
                    mr={0}
                    display="flex"
                    flexDirection="column"
                    onClick={async () => {
                      analytics.log("hit_tweet_addInQueue_generated", {});
                      // let dataToSend = {
                      //     idUser: session?.user?.uid,
                      //     id: tweetContext.id,
                      //     //@ts-ignore
                      //     text: tweetContext.refComposer.current.textState().text,
                      //     isScheduled: true,
                      //     scheduledTime: nextSlot,
                      //     isAutoPlug: isAutoPlug,
                      //     autoPlugTrigger: autoPlugTrigger,
                      //     isAutoRetweet: isAutoRetweet,
                      //     autoRetweetHours: autoRetweetHours,
                      //     autoPlugText: "autoPlugText",
                      //     isRetweeted: false,
                      //     isPlugged: false,
                      // }
                      // await scheduleTweet(undefined, setIsOpenConfirm, dataToSend, session?.user?.data?.timezone);
                    }}
                  >
                    <Text textStyle={textStyle["body.medium.light"]}>
                      Add to Queue
                    </Text>
                  </Button>
                )}
                {mode == "answer" ? (
                  // <Button
                  //   variant="outline"
                  //   size="sm"
                  //   _hover={{
                  //     colorScheme:"twitter",
                  //     bg:"#1A91DA",
                  //     color:"white",
                  //   }}
                  //   target="_blank"
                  //   as="a"
                  //   //@ts-ignore
                  //   href={`https://twitter.com/${tweet.data.twUserName}/status/${tweet.data.id_str}`}
                  //   onClick={() => {
                  //     analytics.log("hit_answer", analytics.formatTweet(tweet.data));
                  //   }}
                  // >
                  //   {"Answer"}
                  // </Button>
                  <></>
                ) : isAuthenticated && !disableEditTweet ? (
                  // <Tooltip label={session?.user?.uid ? "Open the scheduler and prefil with this tweet" : "You need to be signed up to tweet"}>
                  <Box>
                    <Button
                      variant="secondary"
                      size="sm"
                      isDisabled={!session?.user?.uid}
                      onClick={() => {
                        analytics.log(
                          "hit_edit_and_tweet",
                          analytics.formatTweet(tweet.data)
                        );
                        // console.log("hit_edit_and_tweet");
                        // console.log("tweet.data.fullTextAppended: " + tweet.data.fullTextAppended);

                        tweet.data.isVariation = isVariation;

                        //@ts-ignore
                        tweetContext.newTweet({
                          text: tweet.data.fullTextAppended,
                        });
                        //@ts-ignore
                        tweetContext.setSelectedTweet(tweet.data);
                        //@ts-ignore
                        tweetContext.open();
                      }}
                    >
                      {"Edit & post"}
                    </Button>
                  </Box>
                ) : (
                  // </Tooltip>
                  <></>
                )}
                {!disableCheckmark && (
                  <>
                    {/* toto */}
                    {/* <BsCheckCircle fontSize="14px" /> */}
                    <IconButton
                      aria-label="checkmark"
                      color={isSelected ? "green.300" : "gray.200"}
                      variant="invisible"
                      size="lg"
                      height="30px"
                      colorScheme={isSaved ? "gray" : "gray"}
                      icon={<BsCheckCircle fontSize="24px" />}
                    />
                  </>
                )}
                {!disableVotes && (
                  <Flex>
                    <IconButton
                      aria-label="upvote"
                      color={vote == -1 ? "red.300" : "gray.400"}
                      variant="ghost"
                      size="md"
                      // height="30px"
                      icon={<AiOutlineDislike fontSize="24px" />}
                      onClick={() => {
                        if (vote === -1) {
                          setVote(0);
                        } else {
                          setVote(-1);
                        }
                        voteAction(tweet.data, -1);
                      }}
                    />
                    <IconButton
                      aria-label="upvote"
                      color={vote == 1 ? "green.300" : "gray.400"}
                      variant="ghost"
                      size="md"
                      // height="30px"
                      icon={<AiOutlineLike fontSize="24px" />}
                      onClick={() => {
                        if (vote === 1) {
                          setVote(0);
                        } else {
                          setVote(1);
                        }
                        voteAction(tweet.data, 1);
                      }}
                    />
                  </Flex>
                )}
                {!disableSubMenu && (
                  <Popover
                    isLazy
                    lazyBehavior="unmount"
                    initialFocusRef={initRef}
                    placement="bottom"
                    offset={[-77, 4]}
                    eventListeners={{ scroll: false, resize: false }}
                  >
                    {({ isOpen, onClose }) => (
                      <>
                        <PopoverTrigger>
                          <IconButton
                            aria-label="More"
                            // variant="ghost"
                            variant="secondary"
                            size="sm"
                            icon={<FiMoreHorizontal fontSize="12px" />}
                            mr={-1}
                            onClick={() => {
                            }}
                          />
                        </PopoverTrigger>

                        <Portal>
                          <PopoverContent
                            bg={colorWhite}
                            _focus={{ outline: "none" }}
                            border="0"
                            zIndex={4}
                            width={[210, 210]}
                            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
                            css={{ margin: 0, marginTop: 0, marginBottom: 0 }}
                          >
                            <FocusLock returnFocus persistentFocus={false}>
                              <Stack
                                p={2}
                                justifyContent="left"
                                textAlign="left"
                                justifySelf="left"
                              >
                                <CopyToClipboard
                                  text={tweet.data.fullTextAppended}
                                  onCopy={() => {
                                    // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
                                    toast.success("Post copied to clipboard");
                                  }}
                                >
                                  <Button
                                    variant="action"
                                    leftIcon={<BiCopy />}
                                    onClick={() => {
                                      analytics.log(
                                        "hit_open_tweet",
                                        analytics.formatTweet(tweet.data)
                                      );
                                      onClose();
                                    }}
                                    justifyContent="left"
                                  >
                                    Copy / Paste
                                  </Button>
                                </CopyToClipboard>
                                {!disableHideButton &&
                                  removeTweet &&
                                  !isVariation && (
                                    <Button
                                      variant="action"
                                      leftIcon={<ViewOffIcon />}
                                      onClick={async () => {
                                        analytics.log("hit_hide_tweet", analytics.formatTweet(tweet.data));
                                        await hideTweet(tweet.data, getAccount(session)?.id, -1);
                                        removeTweet && removeTweet(tweet.data);
                                        onClose();
                                      }}
                                      justifyContent="left"
                                    >
                                      Hide this post
                                    </Button>
                                  )}
                                {setExcludedUsers && (
                                  <Button
                                    justifyContent="left"
                                    variant="action"
                                    aria-label="Remove this user from appearing"
                                    leftIcon={<AiOutlineMinusCircle />}
                                    onClick={handleOnClickExcludeUser}
                                  >
                                    Exclude User
                                  </Button>
                                )}
                                {
                                  // session?.user?.data?.isAdmin && (
                                  isAuthenticated && !disableTranslate && (
                                    <Box display="flex" alignItems="center">
                                      <Button
                                        // w="200px"
                                        variant="action"
                                        leftIcon={<SiGoogletranslate />}
                                        isLoading={isLoadingTrans}
                                        onClick={async () => {
                                          analytics.log(
                                            "hit_translate",
                                            analytics.formatTweet(tweet.data)
                                          );
                                          setIsLoadingTrans(true);
                                          let response = await fetch(
                                            "/api/translate?text=" +
                                            encodeURIComponent(
                                              tweet.data.full_text
                                            ) +
                                            "&to=" +
                                            languageTrans,
                                            {
                                              method: "GET",
                                              headers: {
                                                "Content-Type":
                                                  "application/json",
                                              },
                                            }
                                          );
                                          setIsLoadingTrans(false);
                                          let data = await response.json();
                                          if (data.translation) {
                                            //@ts-ignore
                                            tweetContext.newTweet({
                                              text: data.translation,
                                            });
                                            //@ts-ignore
                                            tweetContext.setSelectedTweet(
                                              tweet.data
                                            );
                                            //@ts-ignore
                                            tweetContext.open();
                                          } else {
                                            toast.error("Translation failed", {
                                              style: {
                                                background: "gray.600",
                                                color: "#222",
                                              },
                                            });
                                          }
                                        }}
                                        justifyContent="left"
                                      >
                                        Translate
                                      </Button>
                                      <Select
                                        w="50px"
                                        h="100%"
                                        ml={5}
                                        variant="unstyled"
                                        size="sm"
                                        value={languageTrans}
                                        onChange={async (e) => {
                                          e.stopPropagation();
                                          setLanguageTrans(e.target.value);
                                          if (session?.user?.data) {
                                            const db =
                                              firebaseClient.firestore();
                                            await db
                                              .collection("users")
                                              .doc(session?.user?.uid)
                                              .update({
                                                language: e.target.value,
                                              });
                                            session.user.data.language =
                                              e.target.value;
                                          }
                                        }}
                                      >
                                        <option value="es">🇪🇸</option>
                                        <option value="fr">🇫🇷</option>
                                        <option value="de">🇩🇪</option>
                                        <option value="it">🇮🇹</option>
                                        <option value="ru">🇷🇺</option>
                                        <option value="id">🇮🇩</option>
                                        <option value="tr">🇹🇷</option>
                                      </Select>
                                    </Box>
                                  )
                                }
                                {/* // here */}
                                {isAuthenticated && !disableRequest && (
                                  <Button
                                    variant="action"
                                    leftIcon={
                                      <Icon viewBox="0 0 13 16">
                                        <defs>
                                          <linearGradient
                                            id="Gradient"
                                            x1="0"
                                            x2="0"
                                            y1="0"
                                            y2="1"
                                          >
                                            <stop offset="20%" stopColor={"#e86d8a"} />
                                            <stop offset="80%" stopColor={"#527ff0"} />
                                          </linearGradient>
                                        </defs>
                                        <path
                                          d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                          fill="url(#Gradient)"
                                        />
                                      </Icon>
                                    }
                                    onClick={handleVariations}
                                    justifyContent="left"
                                  >
                                    Generate variations
                                  </Button>
                                )}
                                {isAuthenticated && !disableBookmarkButton && (
                                  <Button
                                    onClick={async () => {
                                      setIsOpenBookmark(!isOpenBookmark);
                                      onClose();
                                    }}
                                    variant="action"
                                    leftIcon={
                                      <Icon
                                        viewBox="0 0 17 17"
                                      >
                                        {isSaved ? (
                                          <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                              fill="#ED8936"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                              fill="#ED8936"
                                            />
                                          </svg>
                                        )}
                                      </Icon>
                                    }
                                    justifyContent="left"
                                  >
                                    {isSaved ? "Edit collection" : "Save to collection"}
                                  </Button>
                                  //   <CollectionDropdown
                                  //   inLists={inLists}
                                  //   refresher={refresher}
                                  //   tweet={tweet.data}
                                  //   isOpenPopOver={isOpenPopOver}
                                  //   closePopOver={closePopOver}
                                  //   setIsSaved={setIsSaved}
                                  //   isOpenBookmark={isOpenBookmark}
                                  //   refBookmark={refBookmark}
                                  //       trigger={
                                  //         <Button
                                  //           variant="action"
                                  //           w="full"
                                  //                   onClick={async () => {
                                  //         setIsOpenBookmark(!isOpenBookmark);
                                  //       }}
                                  //       leftIcon={
                                  //         <Icon
                                  //         // color={isSaved ? "orange.300" : "gray.400"}
                                  //         viewBox="0 0 17 17"
                                  //       >
                                  //         {isSaved ? (
                                  //           <svg
                                  //             width="17"
                                  //             height="17"
                                  //             viewBox="0 0 17 17"
                                  //             fill="none"
                                  //             xmlns="http://www.w3.org/2000/svg"
                                  //           >
                                  //             <path
                                  //               d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                  //               fill="#ED8936"
                                  //             />
                                  //           </svg>
                                  //         ) : (
                                  //           <svg
                                  //             width="17"
                                  //             height="17"
                                  //             viewBox="0 0 17 17"
                                  //             fill="none"
                                  //             xmlns="http://www.w3.org/2000/svg"
                                  //           >
                                  //             <path
                                  //               d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                  //               fill="#ED893688"
                                  //             />
                                  //           </svg>
                                  //         )}
                                  //       </Icon>
                                  //     }
                                  //       // onClick={handleVariations}
                                  //       justifyContent="left"
                                  //     >
                                  //       Save to collection
                                  //   </Button>
                                  //     // <IconButton
                                  //     //   aria-label="Bookmark"
                                  //     //   variant={"secondaryBookmark"}
                                  //     //   size="sm"
                                  //     //   onClick={async () => {
                                  //     //     setIsOpenBookmark(!isOpenBookmark);
                                  //     //   }}
                                  //     //   cursor="pointer"
                                  //     //   icon={
                                  //     //     <Icon
                                  //     //       // color={isSaved ? "orange.300" : "gray.400"}
                                  //     //       viewBox="0 0 17 17"
                                  //     //     >
                                  //     //       {isSaved ? (
                                  //     //         <svg
                                  //     //           width="17"
                                  //     //           height="17"
                                  //     //           viewBox="0 0 17 17"
                                  //     //           fill="none"
                                  //     //           xmlns="http://www.w3.org/2000/svg"
                                  //     //         >
                                  //     //           <path
                                  //     //             d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                  //     //             fill="#ED8936"
                                  //     //           />
                                  //     //         </svg>
                                  //     //       ) : (
                                  //     //         <svg
                                  //     //           width="17"
                                  //     //           height="17"
                                  //     //           viewBox="0 0 17 17"
                                  //     //           fill="none"
                                  //     //           xmlns="http://www.w3.org/2000/svg"
                                  //     //         >
                                  //     //           <path
                                  //     //             d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                  //     //             fill="#ED893688"
                                  //     //           />
                                  //     //         </svg>
                                  //     //       )}
                                  //     //     </Icon>
                                  //     //   }
                                  //     //   mr={0}
                                  //     // />
                                  //   }
                                  // />
                                )}
                                {!isVariation && (
                                  <Button
                                    variant="actionDestructive"
                                    leftIcon={<CgDanger />}
                                    onClick={async () => {
                                      analytics.log(
                                        "report_tweet",
                                        analytics.formatTweet(tweet.data)
                                      );
                                      postData({
                                        url: "user-reportTweet",
                                        data: { tweet: tweet.data },
                                        session,
                                        token: "",
                                      });
                                      toast.success(
                                        "Post reported, we will have a look"
                                      );
                                    }}
                                    justifyContent="left"
                                  >
                                    Report post
                                  </Button>
                                )}
                              </Stack>
                            </FocusLock>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                )}
              </Stack>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Tweet;