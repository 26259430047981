import toast from "react-hot-toast";
import {
    Box,
    Button,
    Link,
} from "@chakra-ui/react";
import { firebaseClient, getToken } from "../firebaseClient";
import { shuffleArray } from "../utils/helpers";
import moment from "moment-timezone";
import dateFormat from 'dateformat';
import * as Sentry from "@sentry/nextjs";
import { getAccount, updateUser, showRefreshTokenToast } from "../utils/sessionHelper";
import { format } from "date-fns";
import { convertToAccountTimezone, convertToAccountTimezoneReversed } from "../utils/helpers";
import StringSimilarity from "string-similarity";
import twitterText from "twitter-text";
import { TweetContext } from "context/tweetContext";
import { Slots } from "types/schedule";

export const pushTweet = async (setIsPosting, dataToSend) => {

    try {
        const db = firebaseClient.firestore();

        setIsPosting(true);
        let response = await fetch('/api/pushTweet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
        let data = await response.json();
        setIsPosting(false);

        if (data.success) {
            toast.success("Post pushed successfully", { style: { background: "gray.600", color: "#222" } });
        }
        else {
            toast.error("An error happened. Your post has not been pushed.", { style: { background: "gray.600", color: "#222" } });
            console.log("Error: " + data.error);
        }
    }
    catch (e) {
        toast.error("An error happened. Your post has not been posted.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
};

export const unschedule = async (session, tweet, tweetContext) => {

    console.log("unschedule tweet " + tweet.id);

    const db = firebaseClient.firestore();
    // await db.collection("users").doc(session?.user?.uid).collection("tweets").doc(tweet.id).update({status: "draft"});
    await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tweet.id).update({ status: "draft" });
    // getTweetsFromStatus("draft", setDrafts);
    // getTweetsFromStatus("scheduled", setScheduleds);
    // console.log("unscheduling at: " + tweet.scheduledTime.toDate());
    // console.log("unscheduling at: " + convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date);

    tweetContext.setTabIndex(0);
    tweetContext.newTweet(
        tweet,
        tweet.id,
        false,
        convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date,
    );
    tweetContext.setForceScheduledTime(true);
    // tweetContext.refreshNextSlot();
    // tweetContext.setNextSlot(tweet.scheduledTime.toDate());
    // tweetContext.setTextState({
    //     ...tweetContext.textState,
    //     scheduledTime: convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date,
    //     // scheduledTime: tweet.scheduledTime.toDate(),
    // });
    tweetContext.refresher({});
    tweetContext.setIsTweetTextChanged(true);
}

export const pushTh = async (session, dataToSend, isScheduled, scheduledTime = undefined) => {

    console.log("pushing content to TH: " + dataToSend.text);

    if (!getAccount(session)?.keyTh) {
        console.log("cancel pushTh because no keyTh");
        return;
    }

    let data = {
        type: isScheduled ? "queue" : "now",
        tweet: {
            id: dataToSend.id,
            text: dataToSend.text,
            isScheduled,
        }
    } as any;

    if (scheduledTime) {
        data.tweet.scheduledTime = scheduledTime;
        data.type = "scheduled";
    }

    let response = await fetch('https://app.tweethunter.io/api/public/push', {
        // let response = await fetch('http://localhost:300s0/api/public/push?', {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": getAccount(session)?.keyTh },
        body: JSON.stringify(data),
    });

    if (response?.status != 200) {
        toast.error("An error happened when posting your tweet to Tweet Hunter");
        let json = await response.json();
        console.log("response from TH:", json);
    }
    else {
        let json = await response?.json();
        // console.log(json);
        let sentTweet = json?.sentTweet;

        if (sentTweet?.scheduledTime) {
            let date = new Date(sentTweet?.scheduledTime);
            let dateConverted = convertToAccountTimezone(date, getAccount(session).timezone).date;
            toast.success("Tweet scheduled on Tweet Hunter on " + dateFormat(dateConverted, 'mmm d h:MM tt') + " (" + getAccount(session).timezone + ")");
        }
        else {
            toast.success("Tweet published on Twitter");
        }
    }
}

export const postTweet = async (setIsPosting, setIsOpenConfirm, dataToSend, session) => {

    try {
        const db = firebaseClient.firestore();
        await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);

        setIsPosting && setIsPosting(true);
        let response = await fetch('/api/scheduler', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...dataToSend,
                tokenUserId: session.user.uid,
                token: await getToken(session, "scheduler"),
            }),
        });
        let data = await response.json();
        setIsPosting && setIsPosting(false);

        if (data.success) {

            if (data.id_str) {
                toast(
                    (t) => (
                        <Box>
                            ✅&nbsp;&nbsp;Posted successfully
                            <Button
                                ml={2}
                                variant="secondary"
                                // variant="outline"
                                // colorScheme="twitter"
                                size="xs"
                                as={Link}
                                href={
                                    "https://www.linkedin.com/feed/update/" + data.id_str
                                }
                                isExternal
                            >
                                view
                            </Button>
                        </Box>
                    ),
                    { duration: 6000 }
                );
            }
            else
                toast.success("Posted successfully");

            // dataToSend.status = "sent";
            dataToSend.dateSent = new Date();
            await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);
        }
        else if (data.error === "Unauthorized") {
            showRefreshTokenToast(session);
        }
        else {
            toast.error("An error happened. Your post has not been posted.");
            console.log("Error: " + data.error);
        }

        setIsOpenConfirm && setIsOpenConfirm(false);
    }
    catch (e) {
        toast.error("An error happened. Your post has not been posted.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
};

export const postReply = async (setIsPosting, dataToSend, tweetContext, session) => {

    try {
        setIsPosting(true);
        dataToSend.isInfluentLeader = true;
        const token = await getToken(session, "postReply");
        let response = await fetch('/api/reply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                tokenuserid: session?.user?.uid,
            },
            body: JSON.stringify(dataToSend),
        });
        let data = await response.json();

        setIsPosting(false);

        if (data.success) {
            toast.success("Reply posted");
            return true;
        }
        // else if (data.error === "Viewer/Actor is unauthorized agent") {
        //     toast.error("Your Linkedin account is not allowed to reply to this post");
        //     tweetContext.onOpenCredentials();
        // }
        else if (data.error === "Unauthorized" || data.error.includes("has been revoked")) {
            toast.error("Linkedin token outdated - please refresh it");
            tweetContext.onOpenCredentials();
        }
        else {
            toast.error("An error happened" + (data?.error ? ": " + data?.error : ". Your reply has not been posted."));
            console.log("Error: " + data.error);
        }
    }
    catch (e) {
        toast.error("An error happened. Your post has not been posted.");
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }

    return false;
};


export const scheduleTweet = async (setIsPosting, setIsOpenConfirm, dataToSend, timezone, tweetContext, alreadyTimezoned = false) => {

    // let start = new Date();

    try {
        setIsPosting && setIsPosting(true);
        dataToSend.status = "scheduled";

        // let stringDate = dateFormat(dataToSend.scheduledTime, 'yyyy-mm-dd HH:MM');
        // // console.log("actual time: " + stringDate);
        // let newScheduledTime = moment.tz(stringDate, timezone);
        // // console.log("newScheduledTime: " + newScheduledTime.format());
        // dataToSend.scheduledTime = new Date(newScheduledTime.format());
        console.log("newScheduledTime: " + dataToSend.scheduledTime);

        if (!alreadyTimezoned) {
            // dataToSend.scheduledTime = convertToUserTimezone(dataToSend.scheduledTime, timezone);
            let { date: dateTimezoned } = convertToAccountTimezoneReversed(dataToSend.scheduledTime, timezone);
            console.log("scheduleTweet - dateTimezoned: " + dateTimezoned);
            dataToSend.scheduledTime = dateTimezoned;
        }

        // console.log(dataToSend);
        const db = firebaseClient.firestore();
        if (dataToSend.id) {
            await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);
            toast.success("Post scheduled successfully");
        }
        else {
            throw new Error("Post id not available");
        }

        tweetContext.refresher({});

        setIsOpenConfirm && setIsOpenConfirm(false);
        setIsPosting && setIsPosting(false);
    }
    catch (e) {
        toast.error("An error happened. Your post has not been scheduled.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        setIsPosting && setIsPosting(false);
        setIsOpenConfirm && setIsOpenConfirm(true);
        Sentry.captureException(e);
    }

    // console.log("scheduleTweet - duration: " + (new Date().getTime() - start.getTime()) + "ms");
}

export const getNextSlots = async (session, now = new Date(), nbDaysToGet = 60, conf = {} as any) => {

    try {

        // let start = new Date();
        // console.log("findNextSlot");

        let slots: Slots[] = [];
        let timezoneDiff;
        let firstEmptyDay: Date | undefined = undefined;
        let nbSlots = 0;

        const db = firebaseClient.firestore();
        let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").get();
        let scheduleTweets = docs.docs.map(x => x.data());
        // console.log("getNextSlots", scheduleTweets);
        scheduleTweets = scheduleTweets.filter(x => isTweetFromAccount(x, session));
        // console.log("getNextSlots2", scheduleTweets);

        // if (docs?.docs?.length)
        //     firstEmptyDay = docs?.docs?.sort((a, b) => b.data().scheduledTime.toDate().getTime() - a.data().scheduledTime.toDate().getTime())[0].data().scheduledTime.toDate();
        // console.log("lastTweetDate: " + firstEmptyDay);

        if (getAccount(session).schedule) {
            let schedule = getAccount(session).schedule;
            // console.log(schedule);

            let count = 0;

            ({ date: now, timezoneDiff } = convertToAccountTimezone(now, getAccount(session)?.timezone));
            // console.log("timezoneDiff: " + timezoneDiff);

            let current = new Date(now);

            current.setSeconds(0);
            current.setMilliseconds(0);

            // console.log("findNextSlot init: " + current);
            // console.log("findNextSlot ini: " + current);
            // console.log("findNextSlot ini: " + current.getHours());
            // firstEmptyDay.setTime(firstEmptyDay.getTime() - (1000*60*timezoneDiff));

            // nbDaysToGet = 90;
            let extremeLimit = 1000;

            // will now go as far as possible
            while (count < extremeLimit && (count < nbDaysToGet || (scheduleTweets?.filter(x => !x.hasSlot)?.length > 0 && !conf?.noTempSlots))) {
                // while (count < nbDaysToGet) {

                let newDay: Slots = {
                    time: new Date(current),
                    slotsOfTheDay: []
                };

                slots.push(newDay);

                let startOfDay = new Date(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0, 0);
                let endOfDay = new Date(current.getFullYear(), current.getMonth(), current.getDate(), 23, 59, 59, 999);

                let scheduledTweetsOfTheDay = scheduleTweets.filter(x =>
                ((x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) >= startOfDay.getTime()
                    && (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) < endOfDay.getTime()));
                // console.log("For " + current.toString() + " - nb scheduledTweetsOfTheDay: " + scheduledTweetsOfTheDay.length);
                let isEmptyDay = true;

                // schedule.forEach(t => {
                for (let i = 0; i < schedule.length; i++) {

                    nbSlots++;
                    current.setHours(schedule[i].hour);
                    current.setMinutes(schedule[i].minute ? schedule[i].minute : 0);
                    // let dateTimezoned = convertToUserTimezone(current, session?.user?.data?.timezone);
                    // let dateTimezoned = new Date(current.getTime() - (1000*60*timezoneDiff));
                    let dateTimezoned = current;
                    // console.log("current: " + current)
                    // console.log("dateTimezoned: " + dateTimezoned)

                    let dayIndex = (dateTimezoned.getDay() + 6) % 7;
                    // console.log("dateTimezoned: " + dateTimezoned);
                    // console.log("dayIndex: " + dayIndex);
                    // console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);

                    if (schedule[i].days[dayIndex] && current > now) {

                        let newSlot: any = {
                            time: new Date(dateTimezoned),
                            id: "" + dateTimezoned.getTime(),
                            dayIndex: count,
                            slotIndex: i,
                            isEvergreen: schedule[i].isEvergreen,
                            labels: schedule[i].labels
                        }

                        if (!schedule[i].isEvergreen) {
                            let match = scheduledTweetsOfTheDay.find(x => (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == dateTimezoned.getTime());
                            if (match) {
                                isEmptyDay = false
                                newSlot.tweet = match;
                                newSlot.id = match.id;
                                match.hasSlot = true;
                            }
                        }

                        newDay.slotsOfTheDay.push(newSlot);
                    }
                };

                if (isEmptyDay && !firstEmptyDay && count !== 0) {
                    firstEmptyDay = new Date(current.getTime() - (1000 * 60 * 60 * 24));
                    // console.log("setting firstEmptyDay to " + firstEmptyDay);
                }

                if (!conf?.noTempSlots) {
                    let tweetWithoutSlots = scheduledTweetsOfTheDay.filter(x => !x.hasSlot);
                    tweetWithoutSlots.forEach(tw => {
                        let timeTimezoned = new Date(tw.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff));
                        let newSlot: any = {
                            time: timeTimezoned,
                            id: tw.id,
                            dayIndex: count,
                            slotIndex: newDay.slotsOfTheDay.length,
                            tweet: tw,
                        }
                        newDay.slotsOfTheDay.push(newSlot);
                        tw.hasSlot = true;
                        nbSlots++;
                    });
                }

                // This is why i used ValueOf => https://stackoverflow.com/questions/36560806/the-left-hand-side-of-an-arithmetic-operation-must-be-of-type-any-number-or
                newDay.slotsOfTheDay.sort((a, b) => a.time.valueOf() - b.time.valueOf());

                newDay.slotsOfTheDay.forEach((slot, index) => {
                    slot.slotIndex = index;
                });

                // console.log({current})
                // current.setTime(current.getTime() + (1000*60*60*24)); // cause bug when time changes twice a year
                current.setDate(current.getDate() + 1); // add 1 day
                // console.log({current})
                count++;
            }
        }

        // console.log("getScheduleSlots - duration: " + (new Date().getTime() - start.getTime()) + "ms");
        return { slots, timezoneDiff, firstEmptyDay, scheduleTweets, nbSlots };
    }
    catch (e) {
        console.error("Error in getNextSlots: " + e.message, e);
        return { slots: [], timezoneDiff: 0, firstEmptyDay: new Date(), scheduleTweets: [], nbSlots: 0 };
    }
}

export const findNextSlot = (session, tweets, conf: any = {}) => {

    // console.log("findNextSlot");

    if (getAccount(session).schedule) {
        let schedule = getAccount(session).schedule.filter(x => !x.isEvergreen);
        const confLabels = conf.labels ?? [];
        // console.log(schedule);

        let count = 0;
        let nextSlot = undefined;

        // if code launched from the server, reverse the timezone
        // if (typeof window == "undefined") {
        //     var {timezoneDiff, date: now} = convertToAccountTimezoneReversed(new Date(), getAccount(session)?.timezone);
        //     var current = new Date(now);
        //     // timezoneDiff = -timezoneDiff;
        //     console.log("findNextSlot", {timezoneDiff, now})
        // }
        // else {
        var { timezoneDiff, date: now } = convertToAccountTimezone(new Date(), getAccount(session)?.timezone);
        var current = new Date(now);
        // }

        current.setSeconds(0);
        current.setMilliseconds(0);
        // console.log("findNextSlot first date:", {timezoneDiff, current})

        // console.log("findNextSlot init: " + current);
        // console.log("findNextSlot ini: " + current);
        // console.log("findNextSlot ini: " + current.getHours());

        while (count < 400 && !nextSlot) {
            // schedule.forEach(t => {
            for (let i = 0; i < schedule.length; i++) {

                current.setHours(schedule[i].hour);
                current.setMinutes(schedule[i].minute ? schedule[i].minute : 0);
                // let dateTimezoned = convertToUserTimezone(current, session?.user?.data?.timezone);
                // let dateTimezoned = new Date(current.getTime() - (1000*60*totalDiff));
                let dateTimezoned = current;
                // console.log("current: " + current)
                // console.log("dateTimezoned: " + dateTimezoned)

                let dayIndex = (dateTimezoned.getDay() + 6) % 7;

                if (typeof window == "undefined") {
                    console.log("dateTimezoned: " + dateTimezoned);
                    console.log("dayIndex: " + dayIndex);
                    console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);
                    console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);
                }

                if (schedule[i].days[dayIndex]) {
                    const scheduleLabels = schedule[i].labels ?? [];
                    const hasMatchingLabels = scheduleLabels.some(sl => confLabels.includes(sl))
                    let match = tweets.find(x => (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == dateTimezoned.getTime());
                    // console.log("checking: " + current);
                    // console.log(match);
                    if (current > now && !match && (hasMatchingLabels || (scheduleLabels.length === 0 || confLabels.length === 0))) {
                        // console.log("found a slot: " + current);
                        return current;
                    }
                }
            };

            current.setTime(current.getTime() + (1000 * 60 * 60 * 24)); // add 1 day
            count++;
        }
    }

    return null;
}

export const shareNext = async (session, tweetToSchedule, tweetContext) => {

    // console.log("shareNext");

    let tempTweet = tweetToSchedule;
    let { slots, scheduleTweets } = await getNextSlots(session, new Date(), 120, { noTempSlots: true });
    // console.log({scheduleTweets});
    // console.log(scheduleTweets.map(x => x.scheduledTime.toDate()));
    // console.log({slots});
    const db = firebaseClient.firestore();
    let timezone = getAccount(session)?.timezone;
    let { timezoneDiff } = convertToAccountTimezone(new Date(), getAccount(session)?.timezone);

    let allSlots = [];
    slots.forEach((day: any) => {
        allSlots = allSlots.concat(day.slotsOfTheDay);
    })

    // console.log({allSlots});

    for await (let slot of allSlots as any) {

        if (tempTweet) {
            let match = scheduleTweets.find(x => x?.scheduledTime?.toDate && (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == slot.time.getTime());
            // let match = scheduleTweets.find(x => x?.scheduledTime?.toDate && x.scheduledTime.toDate().getTime() == slot.time.getTime());
            // console.log(scheduleTweets.map(x => x.scheduledTime.toDate().getTime));
            // console.log(slot.time.getTime());

            // console.log
            let { date: dateTimezoned } = convertToAccountTimezoneReversed(slot.time, timezone);
            // console.log("tw: " + tempTweet.text + " / moved to " + dateTimezoned);
            tempTweet.scheduledTime = dateTimezoned;
            await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tempTweet.id).update({ scheduledTime: dateTimezoned });

            if (match) {
                // console.log("already a tweet at this slot: " + slot.time);
                tempTweet = match;
            }
            else {
                // console.log("the end: " + slot.time);
                tempTweet = undefined;
            }
        }

    }

    tweetContext?.refresher && tweetContext.refresher({});
}

export const rebuildQueue = async (session, scheduleTweets, refresher, isShuffle = false) => {

    let error: any = undefined;
    let timezone = getAccount(session)?.timezone;

    const db = firebaseClient.firestore();
    let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").orderBy("scheduledTime", "asc").get();
    let tweets = docs.docs.map(doc => doc.data());

    if (isShuffle) {
        tweets = shuffleArray(tweets);
    }

    // let slots = await getNextSlots(session);
    tweets.forEach(tw => {
        tw.scheduledTime = { toDate: () => { return new Date() } };
    });

    for await (let tw of tweets) {
        try {
            if (!error) {
                // console.log(tweets.map(x => {return {t: x.text, scheduledTime: x.scheduledTime.toDate()}}));
                let slot: any = findNextSlot(session, tweets, { labels: tw.labels || [] });
                console.log("slot: " + slot);
                // let newDate = new Date(slot);
                // tw.scheduledTime = {toDate: () => {return newDate}};

                if (!slot) {
                    console.log("slot not found for this label - removing label");
                    slot = findNextSlot(session, tweets, { labels: [] });
                    console.log("slot without label: " + slot);
                }

                if (!slot?.getMonth)
                    throw new Error("invalid date");

                let { date: dateTimezoned } = convertToAccountTimezoneReversed(slot, timezone);
                await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tw.id).update({ scheduledTime: dateTimezoned });
                let newTweet = await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tw.id).get();
                //@ts-ignore
                tw.scheduledTime = newTweet.data().scheduledTime;
            }
        }
        catch (e) {
            console.log("error in rebuildQueue: " + e.message, JSON.stringify(e));
            error = e;
        }
    };

    if (error) {
        toast.error("Error while rebuilding queue: " + error.message);
    }

    refresher({});
}

export const buildSchedule = async (session, showToast = false, refresher = undefined as any) => {

    try {
        let data: any = null;

        if (getAccount(session).twUserName && session?.user?.data?.thReadAccessToken) {
            let url = `https://api.tweetbutler.com/activity?isInfluentLeader=true&twUserName=${getAccount(session).twUserName}&id=${getAccount(session).id}&timezone=${getAccount(session).timezone}&sizeSample=250&accessToken=${getAccount(session)?.thReadAccessToken}&secretToken=${getAccount(session)?.thReadSecretToken}`;
            const token = await getToken(session, "buildSchedule")
            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    tokenuserid: session.user.uid,
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        let times: Array<any> = [12, 16];

        if (data?.data) {
            let indexMax = data.data.indexOf(Math.max(...data.data));
            times = [
                (indexMax - 4 + 24) % 24,
                (indexMax - 2 + 24) % 24,
                indexMax,
            ];
        }
        else {
            // console.log("failed to build schedule automatically");
        }

        let dataToUpdate: any = {};

        if (!getAccount(session)?.schedule) {
            let schedule = [];
            times.forEach(t => {
                let obj = {
                    hour: t >= 0 && t <= 23 ? t : 0,
                    minute: 0,
                    days: [],
                }
                for (let i = 0; i < 7; i++)
                    //@ts-ignore
                    obj.days[i] = true;

                //@ts-ignore
                schedule.push(obj);
            });
            dataToUpdate.schedule = schedule;
            getAccount(session).schedule = schedule;
        }

        let engagementDistribution = data?.data ?? [];

        if (engagementDistribution?.length && engagementDistribution[0]) {
            dataToUpdate.engagementDistribution = engagementDistribution;
            getAccount(session).engagementDistribution = engagementDistribution;
            // if (showToast)
            //     toast.success("Engagement graph updated");
            if (refresher)
                refresher({});
        }
        else {
            if (showToast)
                toast.error("Failed to update engagement graph");
        }

        // console.log(schedule);
        // const db = firebaseClient.firestore();
        // await db.collection("users").doc(getAccount(session).id).update(dataToUpdate);
        await updateUser(session, dataToUpdate);

        // console.log(data);

    }
    catch (e) {
        console.log("Error in buildSchedule: " + e.message, e);
        Sentry.captureException(e);
    }
}


export const saveSchedule = async (session, newSchedule, refresher) => {

    try {
        // getAccount(session).schedule = newSchedule;
        // const db = firebaseClient.firestore();
        // await db.collection("users").doc(getAccount(session).id).update({schedule: newSchedule});
        toast.success("Schedule updated successfully", { style: { background: "gray.600", color: "#222" } });
        await updateUser(session, { schedule: newSchedule });
        refresher && refresher({});
    }
    catch (e) {
        console.log("Error in buildSchedule: " + e.message, e);
        Sentry.captureException(e);
    }
}

export const isTweetFromAccount = (tweet, session) => {

    return (
        (tweet.idAccount == getAccount(session).idAccount)
        || (!tweet.idAccount && getAccount(session).idAccount == session?.user?.uid)
    )
}

export const saveTweet = async (session, id, dataToSave, createDraft, customSave) => {

    let hasSaved = false;
    // dataToSave.files = updatedFiles ? updatedFiles : refData.current.files;

    const db = firebaseClient.firestore();


    if (getAccount(session)?.id) {
        if (createDraft) {
            try {
                // console.log("will update " + dataToSave.text);
                await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(id).update(dataToSave);
                hasSaved = true;
            }
            catch (e) {
                console.log("draft not created yet, create new draft");
                hasSaved = await createDraft(dataToSave, id);
            }
        }
    }

    if (customSave) {
        await customSave(dataToSave.text);
    }

    return hasSaved;
}

export const cancelPostPublishActions = async (tweet) => {
    try {
        const dataToSend = {
            autoDmStatus: "done",
            isRetweeted: true,
            isPlugged: true,
            isManuallyCancelled: true
        }

        const db = firebaseClient.firestore();
        await db.collection("users").doc(tweet.idUser).collection("tweets").doc(tweet.id).update(dataToSend);

        toast.success("Successfully removed all post-publish actions")
    } catch (e) {
        toast.error("An error happened. Post-Publish actions are not cancelled.");
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
}

function convertToUserTimezone(date, timezone) {
    let stringDate = dateFormat(date, 'yyyy-mm-dd HH:MM');
    console.log("actual time: " + stringDate);
    let newDateMoment = moment.tz(stringDate, timezone);
    console.log("newScheduledTime: " + newDateMoment.format());
    let newDate = new Date(newDateMoment.format());
    console.log("newScheduledTime: " + newDate);

    return newDate;
}

export async function checkTweetValid(threadTweets, session, tweetContext, tweet = {} as any, mainContext, conf = {} as any) {

    let isValid = true;
    let message = "";

    // if (tweet.isScheduled && tweet.scheduledTime && tweet.scheduledTime < convertToAccountTimezoneReversed(new Date(), getAccount(session)?.timezone)?.date) {
    //     isValid = false;
    //     message = "Error: can't schedule in the past";
    // }

    if (tweetContext?.selectedTweet?.full_text) {
        let similarity = StringSimilarity.compareTwoStrings(tweetContext?.selectedTweet?.full_text, tweet.text)
        console.log("similarity score: " + similarity);
        if (similarity > 0.85) {
            isValid = false;
            message = "Error: your post is too similar to the original post.";
        }
    }

    if (!conf.disableCheckLinkedin) {
        if (tweet.text.trimEnd().length > 2800) {
            isValid = false;
            message = "Error: your post is too long";
        }
        if (tweet.text.includes("@") && tweet.text.includes("img:vid-")) {
            isValid = false;
            message = "Error: posts with mentions and videos are not yet supported";
        }
        if (tweet.idCompany && tweet.text.includes("img:vid-")) {
            isValid = false;
            message = "Error: posts with company pages and videos are not yet supported";
        }

        // check if the cookies are OK
        if (tweet.idCompany
            || tweet.text.includes("img:doc-")
            || tweet.text.includes("@")
        ) {
            let response = await fetch('/api/checkCookies', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    tokenUserId: session.user.uid,
                    idUser: getAccount(session).id,
                    token: await getToken(session, "checkTweetValid"),
                }),
            });
            let data = await response.json();
            if (!data?.isValid) {
                isValid = false;
                message = "Error: can't find up-to-date Linkedin cookies. Make sure you have the Taplio Chrome extension up and running.";
            }
        }
    }

    if (conf.checkTwitter) {
        let limit = 5 + ((threadTweets?.length ? threadTweets.length : 0) * 5);
        let countMention = (tweet.text.match(/@/g) || []).length;
        let countHashtag = (tweet.text.match(/\B(\#[a-zA-Z]+\b)(?!;)/g) || []).length;
        if (countHashtag > limit) {
            isValid = false;
            message = "Error: you cannot add that much hashtags";
        }
        if (countMention > limit) {
            isValid = false;
            message = "Error: you cannot add that much mentions";
        }
        threadTweets.map(tw => {
            let matchs = tw.match(/(?:\[img:)(.*?)(?=\])/g);
            if (matchs) {
                matchs.forEach(match => {
                    match = match + "]";
                    tw = tw.replace(match, "");
                });

                if (matchs.length > 4) {
                    isValid = false;
                    message = "Error: each post can have a maximum of 4 images";
                }

                if (matchs.some(x => x.includes("gif-") || x.includes("img:vid-")) && matchs.length > 1) {
                    isValid = false;
                    message = "Error: tweet with gif or video can't have more than 1 media";
                }

                if (matchs.some(x => x.includes("doc-"))) {
                    isValid = false;
                    message = "Error: tweet can't have PDFs";
                }
            }

            let nbChar = twitterText.parseTweet(tw).weightedLength;
            if (nbChar > 280) {
                isValid = false;
                message = "Error: one of your tweet is too long";
            }
            else if (tw.includes("[tweet]") && nbChar > 262) {
                isValid = false;
                message = "Error: one of your tweet will be too long when [tweet] will be replaced by the first tweet URL";
            }
            // console.log(tw + "\nSize: " + nbChar);

            if (!tw.replace(/[\s\n]/g, '').length && !matchs?.length) {
                isValid = false;
                message = "Error: one of your tweet is empty";
            }
        });
    }

    if (tweet.isAutoPlug && !tweet.autoPlugText) {
        isValid = false;
        message = "Error: auto-plug is activated but no message has been set. Deactivate it or add an auto-plug text.";
    }

    if (tweet.isAutoDm && tweet.hasOwnProperty("autoDmText")) { // no need to check for autoDmTrigger, both properties are added at same time in prepareTweet
        const { autoDmText, autoDmTrigger } = tweet;
        if (!autoDmText || !(autoDmTrigger.retweet || autoDmTrigger.reply || autoDmTrigger.like)) {
            isValid = false;
            message = "Error: auto-DM is activated but looks like you forgot to set message or trigger(s). Deactivate it or add text and trigger(s).";
        }
    }

    if (!conf.disableCheckImage && isValid) {
        // check images
        let checkingImage = "";
        let matchs = tweet.text.match(/(?:\[img:)(.*?)(?=\])/g);
        let catchMentions = Boolean(tweet.text.match(/(^|\s+)\B@[A-Za-z0-9_.\-éèçâáàêîôóòùûëïüäöÉÈÀÇÙÂÊÎÔÛËÏÜÄÖ']+/gmi));

        // console.log(matchs);
        if (matchs && matchs.length > 1 && (tweet.idCompany || catchMentions || tweet.text?.includes("img:doc-"))) {
            isValid = false;
            if (tweet.idCompany) message = "Error: you can't publish more than 1 media in a post from company page";
            else if (catchMentions) message = "Error: you can't publish more than 1 media when mentioning someone";
            else if (tweet.text?.includes("img:doc-")) message = "Error: you can't publish more than 1 media when publishing a PDF";
        }
        else if (matchs && matchs.length > 10) {
            isValid = false;
            message = `Error: you can't publish with more than 10 medias`;
        }
        else if (matchs && matchs.length > 0) {
            for await (let match of matchs) {
                try {
                    if (!match.includes("img:vid-") && !match.includes("doc-")) {
                        console.log("checking image: " + match);
                        match = match.replace("[img:", "");
                        checkingImage = "[img:" + match + "]";
                        // match = "N7Up98jXE";
                        let url = "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + match;
                        const response = await fetch(url, { method: "GET" });
                        if (!response.ok) {
                            throw response.status
                        }
                    }
                }
                catch (e) {
                    if (e.message.includes("403")) {
                        console.log("Error in fetching image: " + e.message);
                        isValid = false;
                        message = `Error: the image ${checkingImage} is invalid, remove it to publish your post`;
                    }
                }
            }
        }
    }

    if (!session?.user?.data?.subscription?.isSubscribed && mainContext?.onOpenUpgrade) {
        isValid = false;
        message = "";
        mainContext.onOpenUpgrade();
    }

    if (!isValid && message)
        toast.error(message);

    // if (isValid && (!getAccount(session)?.accessToken || !getAccount(session)?.accounts?.some(x => x.provider === "linkedin"))) {


    if (isValid && !getAccount(session)?.accessToken) {
        tweetContext.onOpenCredentials();
        isValid = false;
    }
    else {
        let now = new Date();
        // console.log(getAccount(session));
        let dateLastUpdate = (getAccount(session)?.dateLastUpdate?._seconds ?? getAccount(session)?.dateSignUp?._seconds) * 1000;
        if (!dateLastUpdate) dateLastUpdate = new Date(2022, 2, 1).getTime();
        let nbDaysSinceLastUpdate = Math.floor((now.getTime() - dateLastUpdate) / (1000 * 60 * 60 * 24));
        // console.log("nbDaysSinceLastUpdate: " + nbDaysSinceLastUpdate);

        if (nbDaysSinceLastUpdate > 50) {
            tweetContext.onOpenCredentials();
            isValid = false;
        }
    }

    console.log("isValid: " + isValid + " / message: " + message);
    // return false;

    return isValid;
}