import React, { useEffect } from "react";
import { postData } from "../utils/helpers";
import toast from "react-hot-toast";
import { setInLocalStorage } from "../utils/helpers";
import {
  Icon,
  Box,
  Button,
  Link,
} from "@chakra-ui/react";
import { signOut } from "next-auth/react";
import * as Sentry from "@sentry/nextjs";
import { firebaseClient, getToken } from "firebaseClient";

const localUrl = "http://localhost:5001/discovr-3175b/us-central1/";
const prodUrl = "https://us-central1-discovr-3175b.cloudfunctions.net/";

let accountFields = [
  "accessToken",
  "access_token",
  "idLinkedin",
  "idLinkedinOfficial",
  "description",
  "bio",
  "who",
  "topics",
  "keywords",
  "tempLevel",
  "thWriteAccessToken",
  "thWriteSecretToken",
  "thApp",
  "twUserName",
  "provider",
  "name",
  "image",
  "needInit",
  "engageAccounts",
  "engagementDistribution",
  "schedule",
  "isAutoRetweet",
  "isAutoPlug",
  "autoRetweetHours",
  "autoRetweetTimes",
  "autoPlugText",
  "autoPlugTrigger",
  "autoPlugList",
  "autoDmText",
  "autoDmTrigger",
  "isAutoDeleteRt",
  "autoDeleteRtHours",
  "preventLinkExpand",
  "preventLinkExpandAutoPlug",
  "companies",
  "isPublicStats",
  "languagePreferred",
  "mentionsProfiles",
  "mentionsEnabled"
];

let forceProd = true;
let baseAuthUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !forceProd ? "http://localhost:3001" : "https://auth2.taplio.com";
// let baseAuthUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !forceProd ? "http://localhost:3001" : "https://auth2.taplio.com";

export const showRefreshTokenToast = (session) => {
  toast((t) => (
    <Box>
      ❌&nbsp;&nbsp;Your Linkedin connection has expired and need to be refreshed
      <Button ml={2} variant="outline"
        // colorScheme="twitter"
        size="xs" as={Link} href={getAuthUrl(session)} isExternal>
        refresh
      </Button>
    </Box>
  ), { duration: 10000 });
}

export const getAuthUrl = (session) => {
  return baseAuthUrl + "/signth?masterUserId=" + getAccount(session)?.id + "&provider=linkedin";
}

export const stopImpersonating = async (session) => {
  console.log(session?.user?.data?.impersonatingOriginUser);
  let response = await fetch('/api/impersonate/stop?id=' + session?.user?.data?.impersonatingOriginUser?.id);
  let data = await response.json();
  if (data?.success)
    location.reload();
  else
    toast.error("Error while stopping impersonation");
}

export const selectAccount = (session, acc, type) => {
  console.log("selectAccount " + acc.id + " / uid: " + session?.user?.uid + " / type: " + type);
  console.log("selectAccount", session);
  if (session?.user?.uid) {
    session.user.selectedAccountType = type;
    session.user.selectedAccountId = acc.idParent;
    session.user.selectedAccount = null;
    setInLocalStorage("selectedAccountId", acc.idParent);
    setInLocalStorage("selectedAccountType", type);
    localStorage.removeItem("selectedAccount");
    getAccount(session);
  }
}

export const deleteCredentialsIfExpired = async (session, code) => {
  if (code && [89, 401, 215].includes(code)) {
    updateUser(session, { thWriteAccessToken: "", thWriteSecretToken: "", thApp: "" });
  }
}

export const updateUser = async (session, data, showToast = false, updateTweetButlerDB = false, conf = {} as any) => {

  const db = firebaseClient.firestore();
  let account = getAccount(session);
  let trueAccount = account;

  if (account?.type === "owned") {
    let match = getAccount(session)?.accounts?.find(x => x.id == getAccount(session).idAccount);
    if (match) {
      trueAccount = match;
    }
  }

  let toSaveGlobal: any = {};
  let toSaveLocal: any = {};
  for (const [key, value] of Object.entries(data)) {
    if (accountFields.includes(key)) {
      // console.log(`update ${key} with value: ${value}`);
      if (!conf.disableUpdateUser) {
        account[key] = value;
        trueAccount[key] = value;
      }
      toSaveLocal[key] = value;
    }
    else {
      if (!conf.disableUpdateUser)
        session.user.data[key] = value;
      toSaveGlobal[key] = value;
    }

    //
    if (!conf.disableUpdateUser && session?.user?.selectedAccount)
      session.user.selectedAccount[key] = value;
  }

  if (account?.type == "owned") {
    // console.log("update account");

    if (Object.keys(toSaveLocal).length > 0)
      await db.collection("users").doc(session?.user?.uid).collection("accounts").doc(account.idAccount).update(toSaveLocal);

    if (Object.keys(toSaveGlobal).length > 0)
      await db.collection("users").doc(session?.user?.uid).update(toSaveGlobal);
    // console.log(getAccount(session));
  }
  else if (account?.type == "shared") {
    console.log("update user");
    console.log(toSaveLocal);
    await db.collection("users").doc(getAccount(session).id).update(toSaveGlobal);

    if (Object.keys(toSaveLocal).length > 0) {
      await db.collection("users").doc(getAccount(session).id).collection("accounts").doc(getAccount(session).idAccount).update(toSaveLocal);
    }
  }
  else {
    console.log("update user but account is not marked as owned or shared")
    if (Object.keys(toSaveGlobal).length > 0)
      await db.collection("users").doc(session?.user?.uid).update(toSaveGlobal);
  }

  if (showToast) {
    toast.success(conf?.toastMessage ?? "Data updated", { style: { background: "gray.600", color: "#222" }, duration: 10000 });
  }

  // if (updateTweetButlerDB) {
  //   postData({
  //     url: "user-updateDataTweetButlerDb",
  //     token: "",
  //     data: {
  //       idUser: getAccount(session)?.idAccount,
  //       data: data,
  //       user: getAccount(session),
  //     },
  //   });
  // }
}

export const logout = () => {
  try {
    localStorage.clear();
  }
  catch (e) {
    Sentry.captureException(e);
  }
  signOut({ callbackUrl: "logout" });
}

export const getAccountId = (session) => {
  let selectedAccountId: any = session?.user?.selectedAccountId;

  if (!selectedAccountId)
    selectedAccountId = localStorage.getItem("selectedAccountId");

  if (!selectedAccountId)
    selectedAccountId = session.user.uid;

  // console.log("selectedAccountId: " + selectedAccountId);

  return selectedAccountId;
}

export const getAccount = (session) => {

  if (!session?.user?.uid)
    return null;

  let selectedAccountId = session?.user?.selectedAccountId;
  // console.log("in session: " + selectedAccountId);

  if (!selectedAccountId && typeof window != "undefined")
    selectedAccountId = localStorage.getItem("selectedAccountId");

  if (selectedAccountId === session?.user?.uid) {
    selectedAccountId = undefined;
    session.user.selectedAccountId = undefined;
  }

  // console.log("in storage selectedAccountId: " + selectedAccountId);
  // console.log("in storage selectedAccount: ", session?.user?.selectedAccount);
  // console.log("accounts: ", session?.user?.data?.accounts);

  if (session?.user?.selectedAccount) {
    // console.log("return from cache");
    return session.user.selectedAccount;
  }
  else if (selectedAccountId) {

    let type = session?.user?.selectedAccountType || (typeof window != "undefined" && localStorage.getItem("selectedAccountType"));
    console.log("getAccount - type: " + type);

    if (type == "shared") {
      let account = getSharedAccount(session, selectedAccountId);
      // console.log("getAccount - shared account: ", account);
      if (account)
        return account;
    }
    else if (type == "owned") {

      // fix inconsistency
      if (selectedAccountId?.includes("auth0")) {
        console.error("Inconsistency in id - selectedAccountId: " + selectedAccountId);
        session.user.selectedAccount = undefined;
        session.user.selectedAccountId = "";
        session.user.selectedAccountType = "";
        localStorage.removeItem("selectedAccountId");
        localStorage.removeItem("selectedAccount");
        localStorage.removeItem("selectedAccountType");
        Sentry.captureException(new Error("Inconsistency in id - selectedAccountId: " + selectedAccountId));
      }

      let account = getOwnedAccount(session, selectedAccountId);
      if (account)
        return account;
    }
  }
  else if (session?.user?.data?.accounts?.length > 0) {
    // console.log("get default account");

    let defaultAccount = session?.user?.data?.accounts[0];
    selectedAccountId = defaultAccount.id;
    session.user.selectedAccountId = defaultAccount.id;
    session.user.selectedAccountType = "owned";

    let account = getOwnedAccount(session, selectedAccountId);
    if (account)
      return account;
  }

  if (session?.user?.data) {
    // console.log("return self");
    return session.user.data;
  }

  return null;
}

function getOwnedAccount(session, selectedAccountId) {

  if (session?.user?.data?.accounts) {
    // console.log("return matching owned account");
    // console.log(session.user.linkAccounts);
    let match = session.user.data.accounts.find(x => x.id === selectedAccountId);
    // console.log('match:', match)

    if (match) {
      session.user.selectedAccountId = selectedAccountId;

      let dataFromMainAccount = {} as any;
      for (const [key, value] of Object.entries(session.user.data)) {
        if (!accountFields.includes(key)) {
          dataFromMainAccount[key] = value;
        }
      }

      let selectedAccount = {
        ...match,
        ...dataFromMainAccount,
        idAccount: match.idAccount,
        type: "owned",
      }
      session.user.selectedAccount = selectedAccount;
      return selectedAccount;
    }
  }

  return null;
}

function getSharedAccount(session, selectedAccountId) {

  if (session?.user?.linkAccounts) {
    console.log("return matching account: " + selectedAccountId);
    console.log(session.user.linkAccounts);

    let match = session.user.linkAccounts.find(x => x.idParent === selectedAccountId);
    if (match) {
      session.user.selectedAccount = match;
      session.user.selectedAccountId = selectedAccountId;
      return match;
    }
    else {

      let selectedAccount: any = localStorage.getItem("selectedAccount");
      if (selectedAccount) {
        selectedAccount = JSON.parse(selectedAccount);
        if (selectedAccount) {
          session.user.selectedAccount = selectedAccount;
          session.user.selectedAccountId = selectedAccount.idParent;
          return selectedAccount;
        }
      }
      else if (selectedAccountId) {
        // console.log("no matching account");
        let db = firebaseClient.firestore();
        db.collection("user").doc(selectedAccountId).get().then((doc) => {
          let user = doc.data();
          setInLocalStorage("selectedAccount", JSON.stringify(user));
          location?.reload && location.reload();
        });
        return null;
      }
    }
  }

  return null;
}