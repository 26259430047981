import { useState, useEffect, useContext } from "react";

import NextLink from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import toast from "react-hot-toast";
import PuffLoader from "react-spinners/PuffLoader";
import { firebaseClient } from "firebaseClient";
import {
  useColorModeValue,
  useMediaQuery,
  Slide,
  Box,
  IconButton,
  Icon,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
} from "@chakra-ui/react";

import { List } from "./list";
import { Notes } from "./notes";
import { Profile } from "./profile";
import { getAccount } from "utils/sessionHelper";
import { layerStyle, textStyle } from "theme/names";
import { Engage } from "./contact-tabs/engage";
import { Summary } from "./contact-tabs/summary";
import { Interactions } from "./contact-tabs/interactions";
import { TweetContext } from "context/tweetContext";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { FiChrome } from "react-icons/fi";


export function PersonSidebar() {
  const [contact, setContact] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const miscellaneousContext: any = useMiscellaneousContext();

  const sidebarBorderColor = useColorModeValue("gray.100", "gray.600");
  const router = useRouter();
  const { data: session } = useSession() ?? {};
  const isSidebarOpen = miscellaneousContext.isPersonSidebarOpen;
  const db = firebaseClient.firestore();
  const idUser = getAccount(session)?.idLinkedin

  useEffect(() => {
    if (
      miscellaneousContext.sidebarPerson &&
      miscellaneousContext.sidebarPerson.id !== contact?.id
    ) {
      getSavedContact(miscellaneousContext.sidebarPerson.id);
    }
  }, [miscellaneousContext.sidebarPerson]);


  const getSavedContact = async (id: string) => {
    try {
      setIsLoading(true);
      const contactDoc = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(id)
        .get();

      const contact = contactDoc.data();
      if (contact) {
        setContact(contact);
        miscellaneousContext.setSidebarPerson({ ...contact });
      } else {
        setContact(miscellaneousContext.sidebarPerson);
      }
    } catch (err) {
      console.log("Error in fetching: ", err);
      toast.error("Error in fetching: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendDm = async () => {
    miscellaneousContext.setCrmDm([contact]);
    miscellaneousContext.setIsPersonSidebarOpen(false);
    router.push({ pathname: "/dm", query: { from: "crm" } }, "/dm");
  };

  const tweetContext: any = useContext(TweetContext);

  if (!miscellaneousContext.sidebarPerson) {
    return null;
  }

  return (
    <Slide
      in={isSidebarOpen}
      style={{
        zIndex: 9,
        width: isTooSmall ? "100%" : "400px",
        overflow: "hidden scroll",
      }}
      className="noScrollBar"
    >
      <Box
        width={isTooSmall ? "100%" : "400px"}
        position="fixed"
        right="0"
        top="0"
        minH="100%"
        layerStyle={layerStyle["bg.border"]}
        borderLeft="1px"
        p="4"
        pb="100px"
        borderColor={sidebarBorderColor}
      >
        <Box {...{ w: "full", pt: 2, pb: 0, alignSelf: "start" }}>
          <IconButton
            aria-label="Close"
            variant="action"
            size="table"
            mr={2}
            onClick={() => {
              miscellaneousContext.setIsPersonSidebarOpen(false);
            }}
            icon={
              <Icon viewBox="0 0 14 11">
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                    fill="currentColor"
                  />
                </svg>
              </Icon>
            }
          />
          <Divider {...{ mt: 4 }}></Divider>
        </Box>
        {!isLoading && contact ? (
          <Box mt="5"
          >
            <Profile contact={contact} />

            <Tabs mt="10" isLazy>
              <TabList sx={{ "border-bottom": "0px", 'justifyContent': 'center' }}>
                <Tab _selected={{ "color": "#2D64BC", "border-color": "#0A66C2" }}>Informations</Tab>
                <Tab _selected={{ "color": "#2D64BC", "border-color": "#0A66C2" }}>Interactions</Tab>
                <Tab _selected={{ "color": "#2D64BC", "border-color": "#0A66C2" }}>Engage</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Summary contact={contact} />
                  <List contact={contact} />
                  <Notes contact={contact} />
                </TabPanel>
                <TabPanel>
                  {
                    idUser ? (
                      <Interactions idUser={idUser} miscellaneousContext={miscellaneousContext} />
                    ) : <>
                      <Text
                        textStyle={textStyle["body.medium.light"]}
                        color="gray.500"
                        mb="4"
                      >
                        We need you to install the Taplio Chrome Extension on your browser to retrieve your last interactions.
                        Please install the Taplio Extension if you haven't.
                      </Text>
                      <NextLink href="/connect" passHref>
                        <Button
                          variant={"primary"}
                          w="full"
                          size="sm"
                          leftIcon={<FiChrome fontSize={"16px"} />}
                        >
                          Install Chrome Extension
                        </Button>
                      </NextLink>

                    </>
                  }
                </TabPanel>
                <TabPanel>
                  <Engage
                    miscellaneousContext={miscellaneousContext}
                    contact={contact}
                    session={session}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        ) : (
          <PuffLoader
            color={"#0A66C2"}
            loading={isLoading}
            cssOverride={{
              display: "block",
              margin: "0 auto",
              borderColor: "red",
            }}
            size={50}
          />
        )}
      </Box>
    </Slide >
  );
}
